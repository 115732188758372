import { render, staticRenderFns } from "./ModelArtCard.vue?vue&type=template&id=5e4b6d21&scoped=true&"
import script from "./ModelArtCard.vue?vue&type=script&lang=ts&"
export * from "./ModelArtCard.vue?vue&type=script&lang=ts&"
import style0 from "./ModelArtCard.vue?vue&type=style&index=0&id=5e4b6d21&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4b6d21",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSource: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppSource.vue').default,AppImage: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppImage.vue').default})
