
















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    name: {
      type: String,
      default: 'newest-arrivals',
    },
    text: {
      type: String,
      default: '',
    },
    as: {
      type: String,
      default: 'button',
    },
  },
});
