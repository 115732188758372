




















































































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    cta: {
      type: String,
      required: false,
      default: null,
    },
    image: {
      type: String,
      required: true,
    },
    mobileImage: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      required: true,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    priority: {
      type: String,
      default: 'auto',
      validator: (option: string) => {
        return ['high', 'low', 'auto'].includes(option);
      },
    },
    dir: {
      type: String,
      default: 'horizontal',
      validator: (option: string) => {
        return ['horizontal', 'vertical'].includes(option);
      },
    },
    blur: {
      type: Boolean,
      default: false,
    },
  },
});
