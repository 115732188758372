//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'auto',
    },
    fetchpriority: {
      type: String,
      default: 'auto',
    },
    decoding: {
      type: String,
      default: 'auto',
    },
    sizes: {
      type: String,
      default: '',
    },
    srcset: {
      type: String,
      default: '',
    },
    usemap: {
      type: String,
      default: '',
    },
    ismap: {
      type: Boolean,
      default: false,
    },
    crossorigin: {
      type: String,
      default: '',
    },
    referrerpolicy: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      required: false,
      default: 'avif',
      validator: value => ['avif', 'webp', 'jpeg'].includes(value),
    },
    auto: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    mainSrc() {
      const imgUrl = this.src
        ? this.$img(this.src, {
            ...(!this.auto && { width: Number(this.width), height: Number(this.height) }),
            fit: 'cover',
            format: this.format,
            enlarge: true,
          })
        : this.generateFakeUrl();

      return imgUrl;
    },
  },
  methods: {
    generateFakeUrl() {
      const url = this.src;
      const params = new URLSearchParams();
      params.append('f', 'jpeg');
      if (!this.auto) params.append('s', '488x650');
      params.append('fit', 'cover');
      params.append('url', url);
      return `/_ipx/${params.toString()}`;
    },
  },
};
