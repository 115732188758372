import { render, staticRenderFns } from "./RoundedText.vue?vue&type=template&id=36ec7ac0&scoped=true&"
import script from "./RoundedText.vue?vue&type=script&lang=ts&"
export * from "./RoundedText.vue?vue&type=script&lang=ts&"
import style0 from "./RoundedText.vue?vue&type=style&index=0&id=36ec7ac0&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ec7ac0",
  null
  
)

/* custom blocks */
import block0 from "./RoundedText.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports