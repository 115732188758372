import { render, staticRenderFns } from "./OffersModelArt1.vue?vue&type=template&id=0f900d47&scoped=true&"
import script from "./OffersModelArt1.vue?vue&type=script&lang=ts&"
export * from "./OffersModelArt1.vue?vue&type=script&lang=ts&"
import style0 from "./OffersModelArt1.vue?vue&type=style&index=0&id=0f900d47&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f900d47",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RoundedSticker: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/RoundedSticker.vue').default,OfferModelArtCard: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/Offer/ModelArtCard.vue').default,OfferSlider: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/OfferSlider.vue').default})
