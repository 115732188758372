



















































import { defineComponent, nextTick, onMounted, onUnmounted, ref, toRef, watch } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Autoplay } from 'swiper';

export default defineComponent({
  name: 'OfferSliderFragment',
  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'primary',
    },
    autoPlay: {
      type: Number,
      default: 0,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    desktopSlides: {
      type: [Number, String],
      default: 2,
    },
    desktopOptions: {
      type: Object,
      default: () => {},
    },
    mobileOptions: { type: Object, default: () => {} },
    arrows: {
      type: Boolean,
      default: false,
    },
    arrowsOutside: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const swiperRef = ref(null);
    const items = toRef(props, 'items');
    const isSliderLoaded = ref(false);
    const currentSlide = ref(1);
    const swiperInstance = ref<Swiper>();

    onMounted(async () => {
      const { default: Swiper, Navigation } = await import('swiper');
      Swiper.use([Navigation, Autoplay]);
      // @ts-ignore
      await import('swiper/swiper-bundle.css');

      isSliderLoaded.value = true;
      await nextTick();

      swiperInstance.value = new Swiper(swiperRef.value || '', {
        slidesPerView: props.items.length === 1 ? 1 : 1.1,
        spaceBetween: 20,
        centeredSlides: false,
        centerInsufficientSlides: true,
        slidesOffsetBefore: 0,
        ...props.mobileOptions,
        autoplay: props.autoPlay
          ? {
              delay: props.autoPlay,
            }
          : false,
        breakpoints: {
          '400': {
            slidesPerView: props.items.length === 1 ? 1 : 1.2,
            spaceBetween: 20,
            ...props.mobileOptions,
          },
          '768': {
            slidesPerView: props.items.length === 1 ? 1 : (props.desktopSlides as number | 'auto'),
            spaceBetween: 30, // revert this to 20
            slidesOffsetBefore: 0,
            centerInsufficientSlides: false,
            centeredSlides: false,
            ...props.desktopOptions,
          },
        },

        on: {
          realIndexChange() {
            currentSlide.value = (swiperInstance.value?.realIndex || 0) + 1;

            emit('change', {
              index: swiperInstance.value?.realIndex,
              item: props.items[swiperInstance.value?.realIndex || 0],
            });
          },
        },
      });

      watch(items, () => {
        swiperInstance.value?.updateSlides();
      });
    });

    watch(
      () => props.currentIndex,
      index => {
        swiperInstance.value?.slideTo(index);
      }
    );

    function nextSlide() {
      // If the slider is on loop mode and the current slide is the last one, we need to go back to the first one
      // Since Swiper's loop feature causes the page to freeze
      if (props.loop && props.currentIndex === props.items.length - 1) {
        swiperInstance.value?.slideTo(0);
        return;
      }

      swiperInstance.value?.slideNext();
    }

    function prevSlide() {
      swiperInstance.value?.slidePrev();
    }

    onUnmounted(() => {
      swiperInstance.value?.destroy();
    });

    return {
      swiperRef,
      isSliderLoaded,
      nextSlide,
      prevSlide,
      currentSlide,
      swiperInstance,
    };
  },
});
