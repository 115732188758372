//
//
//

export default {
  name: 'AppSource',
  props: {
    // @deprecated
    sourceUrl: { type: String, required: false, default: '' },
    srcset: { type: String, required: true },
    sizes: { type: String, required: false, default: '' },
    media: { type: String, required: true },
    width: { type: String, required: true },
    height: { type: String, required: true },
    auto: {
      type: Boolean,
      required: false,
      default: false,
    },
    format: {
      type: String,
      required: false,
      default: 'avif',
      validator: value => ['avif', 'webp', 'jpeg'].includes(value),
    },
    fit: {
      type: String,
      required: false,
      default: 'cover',
      validator: value => ['cover', 'contain'].includes(value),
    },
  },
  computed: {
    mainSrc() {
      const imgUrl = this.srcset
        ? this.$img(this.srcset, {
            ...(!this.auto && { width: Number(this.width), height: Number(this.height) }),
            fit: this.fit,
            format: this.format,
            enlarge: true,
          })
        : this.generateFakeUrl();

      return imgUrl;
    },
    imageType() {
      if (this.format === 'avif') {
        return 'image/avif';
      }

      if (this.format === 'webp') {
        return 'image/webp';
      }

      return 'image/jpeg';
    },
  },
  methods: {
    generateFakeUrl() {
      const url = this.srcset;
      const params = new URLSearchParams();
      params.append('f', 'jpeg');
      if (!this.auto) params.append('s', `${this.width}x${this.height}`);
      params.append('fit', this.fit);
      params.append('url', url);
      return `/_ipx/${params.toString()}`;
    },
  },
};
