var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.content.length)?_c('div',{staticClass:"container mx-auto md:h-auto relative"},[_c('div',{staticClass:"overflow-hidden"},[(_vm.content && _vm.content.length)?_c('OfferSlider',{staticClass:"mt-0 col-span-2 md:col-auto h-full",attrs:{"items":_vm.content,"arrows":"","desktop-slides":1.1,"desktop-options":{
        spaceBetween: 43,
        slidesOffsetAfter: 75,
      },"current-index":_vm.activeSlide,"auto-play":5000,"loop":"","mobile-options":{ spaceBetween: 0, slidesOffsetBefore: 0, slidesPerView: 1 }},on:{"change":function (e) { return (_vm.activeSlide = e.index); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
return [_c('OfferModelArtCard',_vm._b({staticClass:"text-black h-full",class:{ 'hidden md:inline-flex': item.idx !== _vm.activeSlide },attrs:{"blur":item.idx !== _vm.activeSlide,"lazy":false,"priority":item.idx === 0 ? 'high' : 'auto'},scopedSlots:_vm._u([{key:"extras",fn:function(){return [_c('RoundedSticker',{staticClass:"md:mt-8 fill-inverted",attrs:{"text":item.cta,"name":"newest-arrivals","as":"a","href":item.href}})]},proxy:true}],null,true)},'OfferModelArtCard',item,false))]}}],null,false,1941506537)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"[ flex md:flex-col gap-x-3 md:space-y-4 ] [ absolute top-full md:top-1/2 md:right-full transform -translate-y-1/2 -mt-9 ml-5 md:ml-0 mr-7 ] text-base text-gray-500"},_vm._l((_vm.content.length),function(i){return _c('div',{key:i,staticClass:"[ flex md:flex-col items-center justify-center gap-x-4 ]"},[_c('button',{staticClass:"h-8 w-8 cursor-pointer",class:{
          'rounded-full text-white bg-black [ flex items-center justify-center ] ': _vm.activeSlide === i - 1,
        },on:{"click":function($event){_vm.activeSlide = i - 1}}},[_vm._v("\n        "+_vm._s(("0" + i))+"\n      ")]),_vm._v(" "),(_vm.activeSlide === i - 1 && i !== _vm.content.length)?_c('span',{staticClass:"bg-gray-500 w-2 h-unit md:mt-6"}):_vm._e()])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }