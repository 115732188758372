



























































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { OffersContent } from '~/features/home';

export default defineComponent({
  name: 'OffersModelArt1',
  props: {
    content: {
      type: [] as PropType<OffersContent[]>,
      default: () => [],
    },
  },
  setup() {
    const activeSlide = ref(0);

    return {
      activeSlide,
    };
  },
});
